/**
 * --------------------------------------------------------------------------
 * Ace (v4.0.0): index.js
 * --------------------------------------------------------------------------
 */

import Util from '{% static '/util'
import EventHandler from '{% static '/event-handler'
import Basic from '{% static '/general'
import Scrollbar from '{% static '/scrollbar'
import Sidebar from '{% static '/sidebar'
import Aside from '{% static '/aside'
import Toaster from '{% static '/toaster'
import Card from '{% static '/card'
import FileInput from '{% static '/fileinput'
import Wysiwyg from '{% static '/wysiwyg'
import { NavTabScroller, NavLinkScroller } from '{% static '/tab-scroll'
import TabSwipe from '{% static '/tab-swipe'
import ScrollTop from '{% static '/scroll-top'
import Sticky from '{% static '/sticky'

export default {
  Util,
  EventHandler,
  Basic,
  Scrollbar,
  Sidebar,
  Aside,
  Toaster,
  Card,
  FileInput,
  Wysiwyg,
  NavTabScroller,
  NavLinkScroller,
  TabSwipe,
  ScrollTop,
  Sticky
}
